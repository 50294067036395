import { Link } from "@/components";
import { IViewTableColumn } from "@/components/table/ViewTable";
import { routerMap } from "@/router/agent_router";
import WebApi from "@/web/api";
import { Radio } from "antd";
import LibUtils from "lib/utils";
import React from "react";
import CommonReport from "../components/CommonReport";

export default class ProductSaleReportPage extends React.PureComponent {
  state = {
    sortOrder: null,
  };

  private summaryColumns = [
    {
      title: '订货单(笔)',
      field: 'totalOrderCount',
    },
    {
      title: '商品数量(主计量单位)',
      field: 'totalProductCount',
    },
    {
      title: '下单客户(家)',
      field: 'totalCustomerCount',
    },
    {
      title: '订单总额(元)',
      field: 'totalSaleAmount',
      precision: 2
    },
    {
      title: '成本总额(元)',
      field: 'totalPurchaseAmount',
    },
    {
      title: '预估利润(元)',
      field: 'totalProfitAmount',
    }
  ];

  private columns: IViewTableColumn[] = [
    {
      title: '商品',
      dataIndex: 'productName',
      width: 200,
      align: 'left',
      // render: (value, row: any) => {
      //   if (row.orderCount == 0) {
      //     return value;
      //   }
      //   return <Link to={routerMap.reportSaleOrderCustomerDetail.getPath(row['dateRange'].map(i => i.replaceAll('-', '')).join('-'), row.customerId)}>{value}</Link>
      // }
    },
    {
      title: '订货单(笔)',
      dataIndex: 'orderCount',
      align: 'center',
      width: 120,
      sorter: (a: any, b: any) => a.orderCount - b.orderCount,
    },
    {
      title: '数量(主计量单位)',
      dataIndex: 'count',
      align: 'center',
      width: 160,
      sorter: (a: any, b: any) => a.count - b.count,
    },
    {
      title: '下单客户(家)',
      dataIndex: 'customerCount',
      align: 'center',
      width: 120,
      sorter: (a: any, b: any) => a.customerCount - b.customerCount,
    },
    {
      title: '订单均价(元)',
      dataIndex: 'averageSalePrice',
      align: 'center',
      width: 120,
      sorter: (a: any, b: any) => a.averageSalePrice - b.averageSalePrice,
    },
    {
      title: '成本均价(元)',
      dataIndex: 'averagePurchasePrice',
      align: 'center',
      width: 120,
      sorter: (a: any, b: any) => a.averagePurchasePrice - b.averagePurchasePrice,
    },
    {
      title: '订单总额(元)',
      dataIndex: 'saleAmount',
      align: 'center',
      width: 120,
      sorter: (a: any, b: any) => a.saleAmount - b.saleAmount,
    },
    {
      title: '成本总额(元)',
      dataIndex: 'purchaseAmount',
      align: 'center',
      width: 120,
    },
    {
      title: '预估利润(元)',
      dataIndex: 'profitAmount',
      align: 'center',
      width: 120,
      sorter: (a: any, b: any) => a.profitAmount - b.profitAmount,
    }
  ];

  render() {
    return <CommonReport
      // renderQuickFilter={this.renderQuickFilter}
      filter={data => {
        return data.filter(i => i.orderCount > 0);
      }}
      showEmptyLabelText="仅显示有订单发生的商品"
      tableColumns={this.columns}
      pagination={false}
      summaryColumns={this.summaryColumns}
      fetch={data => WebApi.report_product_sale(data)}
    />
  }

  renderQuickFilter = () => {
    return <Radio.Group value="productId" buttonStyle="solid">
      <Radio.Button value="productId">按商品</Radio.Button>
      <Radio.Button value="categoryId">按分类</Radio.Button>
      <Radio.Button value="skuId">按规格</Radio.Button>
    </Radio.Group>
  }
}